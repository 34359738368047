/* ------------------------------------------------------------------------------
 *
 *  # Uniform.js
 *
 *  Styles for uniform.min.js - form components styling
 *
 * ---------------------------------------------------------------------------- */
.uniform-checker,
.uniform-choice {
  position: relative;
  cursor: pointer;
  vertical-align: middle; }
.uniform-checker,
.uniform-checker span,
.uniform-checker input,
.uniform-choice,
.uniform-choice span,
.uniform-choice input {
  width: 1.25rem;
  height: 1.25rem; }
.uniform-checker span,
.uniform-choice span {
  border: 0.125rem solid #455A64;
  display: inline-block;
  text-align: center;
  position: relative; }
.uniform-checker span.checked:after,
.uniform-choice span.checked:after {
  opacity: 1; }
.uniform-checker input[type=checkbox],
.uniform-checker input[type=radio],
.uniform-choice input[type=checkbox],
.uniform-choice input[type=radio] {
  border: 0;
  background: none;
  display: inline-block;
  margin: 0;
  cursor: pointer;
  position: absolute;
  top: -0.125rem;
  left: -0.125rem;
  visibility: visible;
  opacity: 0;
  z-index: 2; }
.form-check.form-check-inline .uniform-checker input[type=checkbox], .form-check.form-check-inline
.uniform-checker input[type=radio], .form-check.form-check-inline
.uniform-choice input[type=checkbox], .form-check.form-check-inline
.uniform-choice input[type=radio] {
  margin-left: 0;
  margin-right: 0; }
.form-check .uniform-checker, .form-check
.uniform-choice {
  position: absolute;
  top: 0.00002rem;
  left: 0; }
.form-check-right .uniform-checker, .form-check-right
.uniform-choice {
  left: auto;
  right: 0; }
.form-check-inline .uniform-checker, .form-check-inline
.uniform-choice {
  position: static;
  margin-right: 0.625rem;
  margin-top: 0.00002rem; }
.form-check-inline.form-check-right .uniform-checker, .form-check-inline.form-check-right
.uniform-choice {
  margin-right: 0;
  margin-left: 0.625rem; }
.uniform-checker.disabled,
.uniform-choice.disabled {
  opacity: 0.5; }
.uniform-checker.disabled,
.uniform-checker.disabled input,
.uniform-choice.disabled,
.uniform-choice.disabled input {
  cursor: default; }

.uniform-checker span {
  color: #455A64;
  border-radius: 0.125rem;
  transition: border-color ease-in-out 0.15s, color ease-in-out 0.15s; }
@media screen and (prefers-reduced-motion: reduce) {
  .uniform-checker span {
    transition: none; } }
.uniform-checker span:after {
  content: "\e600";
  font-family: "icomoon";
  font-size: 1rem;
  position: absolute;
  top: 0rem;
  left: 0rem;
  line-height: 1;
  opacity: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: opacity ease-in-out 0.15s; }
@media screen and (prefers-reduced-motion: reduce) {
  .uniform-checker span:after {
    transition: none; } }
.form-check-light .uniform-checker span {
  border-color: #fff;
  color: #fff; }

.uniform-checker[class*=border-] span {
  border-color: inherit;
  color: inherit; }

.uniform-choice {
  border-radius: 100px; }
.uniform-choice span {
  border-radius: 100px;
  transition: border-color ease-in-out 0.15s; }
@media screen and (prefers-reduced-motion: reduce) {
  .uniform-choice span {
    transition: none; } }
.uniform-choice span:after {
  content: "";
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  border: 0.3125rem solid;
  border-color: inherit;
  width: 0;
  height: 0;
  border-radius: 100px;
  opacity: 0;
  transition: all ease-in-out 0.15s; }
@media screen and (prefers-reduced-motion: reduce) {
  .uniform-choice span:after {
    transition: none; } }
.form-check-light .uniform-choice span {
  border-color: #fff; }
.uniform-choice[class*=border-] span {
  border-color: inherit; }
.uniform-choice[class*=border-] span:after {
  border-color: inherit; }

.dropdown-menu:not([class*=bg-]) .dropdown-item.active:not(.disabled) .uniform-checker span {
  border-color: #fff;
  color: #fff; }

.dropdown-menu:not([class*=bg-]) .dropdown-item.active:not(.disabled) .uniform-choice span {
  border-color: #fff; }

.dropdown-item.form-check .form-check-label {
  padding-left: 3.75rem; }

.dropdown-item.form-check .uniform-checker,
.dropdown-item.form-check .uniform-choice {
  margin-top: 0.50002rem;
  left: 1.25rem; }

.dropdown-item.form-check.form-check-right .form-check-label {
  padding-right: 3.75rem; }

.dropdown-item.form-check.form-check-right .uniform-checker,
.dropdown-item.form-check.form-check-right .uniform-choice {
  left: auto;
  right: 1.25rem; }

.dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-transparent):not(.bg-light) .uniform-checker span {
  border-color: #fff;
  color: #fff; }

.dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-transparent):not(.bg-light) .uniform-choice span {
  border-color: #fff; }

.uniform-uploader {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch; }
.uniform-uploader .filename {
  color: #999;
  padding: 0.5rem 0;
  -ms-flex: 1;
  flex: 1;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #ddd;
  text-align: left;
  word-break: break-word; }
.uniform-uploader .text-white ~ .filename {
  color: #fff;
  border-bottom-color: rgba(255, 255, 255, 0.5); }
.uniform-uploader .action {
  margin-left: 1.25rem;
  z-index: 1;
  border-radius: 0.1875rem; }
.uniform-uploader input[type=file] {
  width: 100%;
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  min-height: 2.37503rem;
  border: 0;
  cursor: pointer;
  z-index: 10;
  opacity: 0; }
.uniform-uploader.disabled .filename {
  border-bottom-style: dashed; }
.uniform-uploader.disabled .action {
  opacity: 0.75; }
.uniform-uploader.disabled .filename,
.uniform-uploader.disabled input[type=file] {
  cursor: default; }

.form-control-styled-lg input[type=file] {
  min-height: 2.62502rem; }

.form-control-styled-lg .filename {
  padding: 0.625rem 0;
  font-size: 0.875rem;
  line-height: 1.4286; }

.form-control-styled-sm input[type=file] {
  min-height: 2.12502rem; }

.form-control-styled-sm .filename {
  padding: 0.375rem 0;
  font-size: 0.75rem;
  line-height: 1.6667; }

.uniform-select {
  position: relative;
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  font-size: 0.8125rem;
  line-height: 1.5385;
  color: #333;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #ddd;
  transition: all ease-in-out 0.15s; }
@media screen and (prefers-reduced-motion: reduce) {
  .uniform-select {
    transition: none; } }
.uniform-select.focus {
  border-bottom-color: #009688;
  box-shadow: 0 1px 0 #009688; }
.uniform-select.text-white {
  border-bottom-color: rgba(255, 255, 255, 0.5); }
.uniform-select.text-white.focus {
  border-bottom-color: #fff;
  box-shadow: 0 1px 0 #fff; }
.uniform-select span {
  display: block;
  position: relative;
  text-align: left;
  padding-right: 1.5rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
.uniform-select span:after {
  content: '\e9c5';
  font-family: "icomoon";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;
  margin-top: -0.12502rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
.uniform-select select {
  width: 100%;
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 2.37503rem;
  border: 0;
  cursor: pointer;
  z-index: 10;
  opacity: 0; }
.uniform-select.disabled {
  color: #999;
  border-bottom-style: dashed; }
.uniform-select.disabled select {
  cursor: default; }

.form-control-styled-lg .uniform-select {
  padding: 0.625rem 0;
  font-size: 0.875rem;
  line-height: 1.4286; }

.form-control-styled-sm .uniform-select {
  padding: 0.375rem 0;
  font-size: 0.75rem;
  line-height: 1.6667; }
