@import url('https://fonts.googleapis.com/css?family=Roboto:400,300,100,500,700,900&display=swap');

@import "base/css/vendor/icons/icomoon/styles.scss";
@import "base/css/bootstrap.scss";
@import "base/css/bootstrap_limitless.scss";
@import "base/css/layout.scss";
//@import "base/css/components.scss";

//@import "base/css/components/inputs";
//@import "base/css/components/pace";
//@import "base/css/components/progress";
@import "base/css/components/uniform";
//@import "base/css/components/switchery";
//@import "base/css/components/switches";
//@import "base/css/components/select2";
//@import "base/css/components/multiselect";
//@import "base/css/components/typeahead";
//@import "base/css/components/validate";
//@import "base/css/components/floating_labels";
//@import "base/css/components/alpaca_forms";
//@import "base/css/components/tokenfield";
//@import "base/css/components/tagsinput";
//@import "base/css/components/touchspin";
//@import "base/css/components/duallistbox";
//@import "base/css/components/steps";
//@import "base/css/components/summernote"; // --
//@import "base/css/components/trumbowyg";
//@import "base/css/components/ace_code_editor";
//@import "base/css/components/daterange_picker";
//@import "base/css/components/pick_a_date";
//@import "base/css/components/anytime_picker";
//@import "base/css/components/spectrum_color_picker";
//@import "base/css/components/plupload";
//@import "base/css/components/fileinput";
@import "base/css/components/dropzone";
//@import "base/css/components/noty";
//@import "base/css/components/pnotify";
//@import "base/css/components/jgrowl";
//@import "base/css/components/sweet_alert";
//@import "base/css/components/slider_pips";
//@import "base/css/components/nouislider";
//@import "base/css/components/ion_rangeslider";
//@import "base/css/components/jquery_ui_interactions";
//@import "base/css/components/ripple";
//@import "base/css/components/prism";
//@import "base/css/components/slinky";
//@import "base/css/components/sticky";
//@import "base/css/components/headroom";
//@import "base/css/components/perfect_scrollbar";
//@import "base/css/components/fab";
//@import "base/css/components/fancytree_all";
//@import "base/css/components/ladda";
//@import "base/css/components/fullcalendar";
//@import "base/css/components/cropper";
//@import "base/css/components/fancybox";
//@import "base/css/components/footable";
//@import "base/css/components/handsontable";
//@import "base/css/components/datatables";
//@import "base/css/components/columns_row_reorder";
//@import "base/css/components/fixed_header_extension";
//@import "base/css/components/autofill_extension";
//@import "base/css/components/select_extension";
//@import "base/css/components/buttons_extension";
//@import "base/css/components/key_table_extension";
//@import "base/css/components/responsive_extenstion";
//@import "base/css/components/demo_styles"; // --
@import "base/css/components/theme_specific_styles";
@import "base/css/components/helper_classes";
@import "base/css/components/header_elements";
@import "base/css/components/error_pages";
//@import "base/css/components/chat_layouts";
//@import "base/css/components/timeline";
@import "base/css/components/login_form";
//@import "base/css/components/user_profile";
//@import "base/css/components/mail_list";
@import "base/css/components/task_manager";
//@import "base/css/components/blog_layouts";
//@import "base/css/components/ecommerce_stuff";
//@import "base/css/components/d3";
//@import "base/css/components/c3";
//@import "base/css/components/chart_styling";
//@import "base/css/components/vector_maps";
@import "base/css/colors.scss";

@import "variables.scss";

@import "attachments.scss";
// @import "dateRangePicker.scss"; // Old styles for react-dates
// @import "dateTimePicker.scss"; // Old custom styles for react-widgets component
// @import "datepicker.scss"; // Old styles for react-dates
@import "reactDateTimePicker.scss";
@import "~react-datepicker/dist/react-datepicker.css";
@import "grid_layout.scss";
@import "horizontal_scroll.scss";
@import "markdown.scss";
@import "email_response_cleanup.scss";
//@import "wysiwyg.scss";
@import "reactImageLightbox.scss";
@import "fslightbox_custom.scss";
@import "doka_custom.scss";

@import "custom.scss";
