//** HORIZONTAL SCROLL **//

.horizontal-scroll-fade {
  position: relative;
  overflow-x: auto;
}

.horizontal-scroll-fade > .horizontal-scroll-content {
  overflow-x: auto;
}

.horizontal-scroll-fade:before,
.horizontal-scroll-fade:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  bottom:0;
  width: 36px;
  z-index: 1;
}

.horizontal-scroll-fade.start:before {
  opacity: 0;
  pointer-events: none;
}

.horizontal-scroll-fade.end:after {
  opacity: 0;
  pointer-events: none;
}

.horizontal-scroll-fade:before {
  left:0;
}

.horizontal-scroll-fade:after {
  right:0;
}

.bg-light .horizontal-scroll-fade:before {
  background: linear-gradient(90deg, #fafafaff 12%, #fafafa00);
}

.bg-light .horizontal-scroll-fade:after {
  background: linear-gradient(90deg, #fafafa00, #fafafaff 88%);
}