/* ------------------------------------------------------------------------------
 *
 *  # Task manager
 *
 *  Styles related to task manager - detailed task, list and grid
 *
 * ---------------------------------------------------------------------------- */
.timer-number {
  font-size: 2.875rem;
  line-height: 1; }

.timer-dots {
  font-size: 1.4375rem;
  padding: 0.71875rem 0;
  line-height: 1; }
