/* ------------------------------------------------------------------------------
 *
 *  # Error pages
 *
 *  Styles for error and offline pages
 *
 * ---------------------------------------------------------------------------- */
.error-title {
  color: #fff;
  font-size: 8.125rem;
  line-height: 1;
  margin-bottom: 2.5rem;
  font-weight: 300;
  text-stroke: 1px transparent;
  display: block;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15); }
@media (min-width: 576px) {
  .error-title {
    font-size: 12.5rem; } }

.offline-title {
  font-size: 6.875rem; }
@media (min-width: 576px) {
  .offline-title {
    font-size: 10rem; } }
