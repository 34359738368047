//** GRID PAGE LAYOUT WITH SIDEBAR **//

.grid-one-column {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
}

.grid-md-sidebar {
  display: grid;
  grid-gap: $sidebar-gap;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas: "main";
  
  .sidebar-mobile &,
  .modal-body & {
    grid-template-areas: "sidebar" "main";
  }

  .grid-item-sidebar {
    grid-area: sidebar;
    display: none;
    .sidebar-mobile &,
    .modal-body & {
      display: initial;
    }
  }

  @media (min-width: $breakpoint-md) {

    &.grid-sidebar-right {
      grid-template-columns: minmax(0, 1fr) $sidebar-width-default;
      grid-template-areas: "main sidebar";
      
      &.sidebar-sm {
        grid-template-columns: minmax(0, 1fr) $sidebar-width-small;
      }
    }
    
    &.grid-sidebar-left {
      grid-template-columns: $sidebar-width-default minmax(0, 1fr);
      grid-template-areas: "sidebar main";
      &.sidebar-sm {
        grid-template-columns: $sidebar-width-small minmax(0, 1fr);
      }
    }

    .sidebar-hidden & {
      grid-template-columns: minmax(0, 1fr);
      grid-template-areas: "main";
    }
  
    .grid-item-sidebar {
      grid-area: sidebar;
      display: initial;
      .sidebar-hidden & {
        display: none;
      }
    }

  }
 
  .grid-item-main {
    grid-area: main;
  }

}

/* flexbox fallback is the browser does not support display:grid */
@supports not (display: grid) {

  .grid-md-sidebar {
    display: flex;
    flex-flow: column;
    .sidebar-mobile & {
      @media (min-width: $breakpoint-md) {
        flex-flow: row;
      }
    }
  }

  .grid-item-sidebar {
    .sidebar-mobile & {
      margin: 0px;
      order: -1;
    }
    @media (min-width: $breakpoint-md) {
      .grid-sidebar-right & {
        margin-left: $sidebar-gap;
        order: 999;
      }
      .grid-sidebar-left & {
        margin-right: $sidebar-gap;
        order: -1;
      }
      flex-basis: 0;
      min-width: $sidebar-width-default;
      .sidebar-sm & {
        min-width: $sidebar-width-small;
      }
    }
  }

  .grid-item-main {
    overflow: auto;
    width: 100%;
  }

}
