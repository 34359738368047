/* ------------------------------------------------------------------------------
 *
 *  # Dropzone file uploader
 *
 *  Styles for dropzone.min.js - open source library that provides drag’n’drop file uploads with image previews
 *
 * ---------------------------------------------------------------------------- */
.dropzone {
  position: relative;
  border: 2px dashed rgba(0, 0, 0, 0.125);
  min-height: 18rem;
  background-color: #fff;
  padding: 0.3125rem;
  border-radius: 0.1875rem; }
.card .dropzone {
  background-color: #fcfcfc;
  border-color: #ddd; }
.dropzone .dz-message {
  opacity: 1; }
.dropzone.dz-started .dz-message {
  display: none; }
.dropzone.dz-drag-hover {
  border-color: #999;
  background-color: #f5f5f5;
  color: #ddd; }
.sidebar .dropzone {
  text-align: center; }
.sidebar .dropzone .dz-default.dz-message:before {
  font-size: 4rem;
  width: 4rem;
  height: 4rem;
  margin-left: -2rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }
.sidebar .dropzone .dz-default.dz-message span {
  font-size: 0.9375rem; }

.dropzone .dz-default.dz-message {
  height: 18rem;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  opacity: 1;
  border-radius: 0.1875rem;
  transition: opacity ease-in-out 0.15s; }
@media screen and (prefers-reduced-motion: reduce) {
  .dropzone .dz-default.dz-message {
    transition: none; } }
.dropzone .dz-default.dz-message:before {
  content: '\ea0e';
  font-family: "icomoon";
  font-size: 4rem;
  display: inline-block;
  position: absolute;
  top: 5rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  line-height: 1;
  z-index: 2;
  color: #ccc;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
.dropzone .dz-default.dz-message > span {
  font-size: 1.0625rem;
  color: #777;
  display: block;
  margin-top: 10.25rem; }
.dropzone .dz-default.dz-message > span span {
  display: block;
  color: #9d9d9d;
  font-size: 0.9375rem; }

.dropzone.dz-drag-hover .dz-message {
  opacity: 0.75; }

.dropzone.dz-started .dz-message {
  display: block;
  opacity: 0; }

.dropzone .dz-preview,
.dropzone-previews .dz-preview {
  background-color: #fff;
  position: relative;
  display: inline-block;
  margin: 0.5rem;
  min-width: 10rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 0.1875rem; }
.dropzone .dz-preview.dz-file-preview [data-dz-thumbnail],
.dropzone-previews .dz-preview.dz-file-preview [data-dz-thumbnail] {
  display: none; }
.dropzone .dz-preview .dz-details,
.dropzone-previews .dz-preview .dz-details {
  position: relative; }
.dropzone .dz-preview .dz-details .dz-filename,
.dropzone-previews .dz-preview .dz-details .dz-filename {
  overflow: hidden;
  margin-top: 0.625rem; }
.dropzone .dz-preview .dz-details img,
.dropzone-previews .dz-preview .dz-details img {
  position: absolute;
  top: 0;
  left: 0; }
.dropzone .dz-preview .dz-details .dz-size,
.dropzone-previews .dz-preview .dz-details .dz-size {
  margin-top: 0.625rem; }
.dropzone .dz-preview:hover .dz-details img,
.dropzone-previews .dz-preview:hover .dz-details img {
  display: none; }
.dropzone .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-success-mark,
.dropzone-previews .dz-preview .dz-error-mark,
.dropzone-previews .dz-preview .dz-success-mark {
  display: none;
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  padding: 0.25rem;
  background-color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0 0 0 0.1875rem; }
.dropzone .dz-preview .dz-error-mark:after,
.dropzone .dz-preview .dz-success-mark:after,
.dropzone-previews .dz-preview .dz-error-mark:after,
.dropzone-previews .dz-preview .dz-success-mark:after {
  font-family: "icomoon";
  display: block;
  font-size: 0.8125rem;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
.dropzone .dz-preview .dz-error-mark span,
.dropzone .dz-preview .dz-error-mark svg,
.dropzone .dz-preview .dz-success-mark span,
.dropzone .dz-preview .dz-success-mark svg,
.dropzone-previews .dz-preview .dz-error-mark span,
.dropzone-previews .dz-preview .dz-error-mark svg,
.dropzone-previews .dz-preview .dz-success-mark span,
.dropzone-previews .dz-preview .dz-success-mark svg {
  display: none; }
.dropzone .dz-preview .dz-error-mark,
.dropzone-previews .dz-preview .dz-error-mark {
  color: #F44336; }
.dropzone .dz-preview .dz-error-mark:after,
.dropzone-previews .dz-preview .dz-error-mark:after {
  content: '\ed6a'; }
.dropzone .dz-preview .dz-success-mark,
.dropzone-previews .dz-preview .dz-success-mark {
  color: #4CAF50; }
.dropzone .dz-preview .dz-success-mark:after,
.dropzone-previews .dz-preview .dz-success-mark:after {
  content: '\ed6e'; }
.dropzone .dz-preview.dz-error .dz-error-mark,
.dropzone .dz-preview.dz-success .dz-success-mark,
.dropzone-previews .dz-preview.dz-error .dz-error-mark,
.dropzone-previews .dz-preview.dz-success .dz-success-mark {
  display: block;
  opacity: 1; }
.dropzone .dz-preview .dz-progress,
.dropzone-previews .dz-preview .dz-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.25rem;
  display: none; }
.dropzone .dz-preview .dz-progress .dz-upload,
.dropzone-previews .dz-preview .dz-progress .dz-upload {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #4CAF50;
  transition: width ease-in-out 0.15s;
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem; }
@media screen and (prefers-reduced-motion: reduce) {
  .dropzone .dz-preview .dz-progress .dz-upload,
  .dropzone-previews .dz-preview .dz-progress .dz-upload {
    transition: none; } }
.dropzone .dz-preview.dz-processing .dz-progress,
.dropzone-previews .dz-preview.dz-processing .dz-progress {
  display: block; }
.dropzone .dz-preview.dz-success .dz-progress,
.dropzone-previews .dz-preview.dz-success .dz-progress {
  display: block;
  opacity: 0;
  transition: opacity ease-in-out 0.15s; }
@media screen and (prefers-reduced-motion: reduce) {
  .dropzone .dz-preview.dz-success .dz-progress,
  .dropzone-previews .dz-preview.dz-success .dz-progress {
    transition: none; } }
.dropzone .dz-preview .dz-error-message,
.dropzone-previews .dz-preview .dz-error-message {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0.5rem;
  padding: 0.5rem;
  color: #F44336;
  max-width: 100%;
  z-index: 5;
  opacity: 0;
  transition: opacity ease-in-out 0.15s; }
@media screen and (prefers-reduced-motion: reduce) {
  .dropzone .dz-preview .dz-error-message,
  .dropzone-previews .dz-preview .dz-error-message {
    transition: none; } }
.dropzone .dz-preview.dz-error:hover .dz-error-message,
.dropzone-previews .dz-preview.dz-error:hover .dz-error-message {
  display: block;
  opacity: 1; }
.dropzone .dz-preview.dz-error .dz-progress .dz-upload,
.dropzone-previews .dz-preview.dz-error .dz-progress .dz-upload {
  background: #F44336; }
.dropzone .dz-preview.dz-image-preview:hover .dz-details img,
.dropzone-previews .dz-preview.dz-image-preview:hover .dz-details img {
  display: block;
  opacity: 0.1; }
.dropzone .dz-preview .dz-image img,
.dropzone-previews .dz-preview .dz-image img {
  width: 100%; }
.dropzone .dz-preview a.dz-remove,
.dropzone-previews .dz-preview a.dz-remove {
  background-color: #f5f5f5;
  border: 1px solid transparent;
  display: block;
  padding: 0.375rem 0.875rem;
  text-align: center;
  color: #333;
  margin-top: 0.625rem;
  cursor: pointer;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s; }
@media screen and (prefers-reduced-motion: reduce) {
  .dropzone .dz-preview a.dz-remove,
  .dropzone-previews .dz-preview a.dz-remove {
    transition: none; } }
.dropzone .dz-preview a.dz-remove:hover,
.dropzone-previews .dz-preview a.dz-remove:hover {
  color: #333;
  background-color: #eee;
  border-color: transparent; }
