.react-datepicker {
  // Fix Time panel shifting
  display: flex !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  text-transform: capitalize;
}

// DateRange ===>>>
.ReactDatepickerDateRange {
  background-color: #f5f5f5;
  .react-datepicker-wrapper {
    width: calc(50% - 40px);
  }
  input {
    font-weight: 500;
  }
  .form-control {
    border-bottom-color: transparent;
  }
}
// <<<=== DateRange

.react-datepicker-popper {
  .react-datepicker {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12),
      0 1px 36px rgba(0, 0, 0, 0.18);
    overflow: hidden;
    font-size: 14px;
    border: none;
  }
}

.react-datepicker {
  border-color: #dedede !important;
  .react-datepicker__navigation {
    top: 1.1em;
    &.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 7.75em;
    }
  }
  .react-datepicker__header {
    background: none;
    border-bottom: 0;
    padding: 0.75em 0.75em 0;
    .react-datepicker__day-names {
      margin-top: 1.5em;
      font-size: 0.8em;
    }
    .react-datepicker__day-name {
      color: #757575;
      margin: 0.1em;
      width: 3em;
      line-height: 3em;
      margin-bottom: 0;
    }
  }
  .react-datepicker__month {
    padding: 0 0.75em 0.25em;
    margin: 0;
    .react-datepicker__day {
      margin: 0.1em;
      width: 2.4em;
      line-height: 2.4em;
      border-radius: 0.2em;
      &.react-datepicker__day--outside-month {
        background-color: #f5f5f5;
        color: #bebebe;
      }
      &.react-datepicker__day--in-range,
      &.react-datepicker__day--selected {
        color: white;
        background-color: rgba($color: #2196f3, $alpha: 1);
      }
    }
    .react-datepicker__day--today {
      font-weight: 600;
      &:not(.react-datepicker__day--in-range) {
        background: #ffefd5;
        color: #333;
      }
      &.react-datepicker__day--in-range {
        background-color: rgba($color: #1565c0, $alpha: 1);
      }
    }
    .react-datepicker__day--in-selecting-range {
      &:not(.react-datepicker__day--in-range) {
        color: white;
        background-color: rgba($color: #2196f3, $alpha: 0.5);
      }
    }
    &.react-datepicker__month--selecting-range {
      .react-datepicker__day--in-selecting-range {
        &.react-datepicker__day--today {
          background-color: rgba($color: #1565c0, $alpha: 0.5);
          color: white;
        }
      }
      .react-datepicker__day--in-range {
        &.react-datepicker__day--today {
          background-color: rgba($color: #1565c0, $alpha: 1);
        }
        &:not(.react-datepicker__day--in-selecting-range) {
          background-color: hsl(0, 0%, 90%);
          color: #757575;
          &.react-datepicker__day--today {
            color: rgba($color: #333, $alpha: 0.75);
            background-color: rgba($color: #ffefd5, $alpha: 0.5);
          }
        }
      }
    }
  }
  .react-datepicker__time-container {
    width: auto;
    border-left-color: #dedede;
    .react-datepicker__time-box {
      width: auto;
    }
    .react-datepicker__header--time {
      border-bottom: 1px solid #dedede;
      padding-bottom: 0.75em;
    }
    .react-datepicker__time-list-item--selected {
      background-color: rgba($color: #2196f3, $alpha: 1) !important;
    }
  }
}

button.react-datepicker__close-icon {
  &::after {
    width: 20px;
    height: 20px;
    font-size: 20px;
    background-color: transparent;
    color: rgba($color: #cdcdcd, $alpha: 1);
  }
  
  &:hover::after {
    color: rgba($color: #999999, $alpha: 1);
  }
}
